body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar-nav .nav-item {
    display: inline-block;
}
.RegulationCard {
    display: flex;
    align-items: center;
    padding: 0 12px;
    grid-gap: 10px;
    gap: 10px;
    border-radius: 4px;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.19);
    margin-bottom: 12px;
    justify-content: space-between;
}

.RegulationCard .Body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    grid-gap: 8px;
    gap: 8px;
    padding: 16px 0px;
}

.RegulationCard .Body .Name {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    align-self: stretch;
    margin: 0;
}

.RegulationCard .Body .Description {
    color: #697077;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    align-self: stretch;
}

.RegulationCard .Chevron {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
}
.DocumentOptionCard {
    display: flex;
    align-items: center;
    padding: 0 12px;
    grid-gap: 10px;
    gap: 10px;
    border-radius: 4px;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.19);
    margin-bottom: 8px;
    justify-content: space-between;
    cursor: pointer;
}

.DocumentOptionCard .Body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    grid-gap: 2px;
    gap: 2px;
    padding: 10px 0px;
}

.DocumentOptionCard .Body .Name {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    align-self: stretch;
    margin: 0;
}

.DocumentOptionCard .Body .Path {
    color: #697077;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    align-self: stretch;
}

.DocumentOptionCard .Chevron {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
}
.QuestionResponse .Quote {
    padding: 10px 10px 10px 18px;
    border-left: 3px solid #CDCDCE;
    background: #FAFAFA;
    margin-bottom: 10px;
    white-space: pre-line;
}

.QuestionResponse .Footer {
    color: #697077;
    font-size: 12px;
    font-weight: 400;
    line-height: 150%;
}

.QuestionResponse .Footer .Link {
    color: #036CD5;
    font-size: 12px;
    font-weight: 400;
    line-height: 150%;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
}
.ResultHeading {
    margin: 18px 0 12px 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 120%;
}
