.RegulationCard {
    display: flex;
    align-items: center;
    padding: 0 12px;
    gap: 10px;
    border-radius: 4px;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.19);
    margin-bottom: 12px;
    justify-content: space-between;
}

.RegulationCard .Body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    gap: 8px;
    padding: 16px 0px;
}

.RegulationCard .Body .Name {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    align-self: stretch;
    margin: 0;
}

.RegulationCard .Body .Description {
    color: #697077;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    align-self: stretch;
}

.RegulationCard .Chevron {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
}