.DocumentOptionCard {
    display: flex;
    align-items: center;
    padding: 0 12px;
    gap: 10px;
    border-radius: 4px;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.19);
    margin-bottom: 8px;
    justify-content: space-between;
    cursor: pointer;
}

.DocumentOptionCard .Body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    gap: 2px;
    padding: 10px 0px;
}

.DocumentOptionCard .Body .Name {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    align-self: stretch;
    margin: 0;
}

.DocumentOptionCard .Body .Path {
    color: #697077;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    align-self: stretch;
}

.DocumentOptionCard .Chevron {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
}