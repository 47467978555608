.QuestionResponse .Quote {
    padding: 10px 10px 10px 18px;
    border-left: 3px solid #CDCDCE;
    background: #FAFAFA;
    margin-bottom: 10px;
    white-space: pre-line;
}

.QuestionResponse .Footer {
    color: #697077;
    font-size: 12px;
    font-weight: 400;
    line-height: 150%;
}

.QuestionResponse .Footer .Link {
    color: #036CD5;
    font-size: 12px;
    font-weight: 400;
    line-height: 150%;
    text-decoration-line: underline;
}